@import "../../../../variables";

.container {
    display: flex;
    padding: 0.8rem 0 0 0;
    border-bottom: 1px solid $calendar-lines;
    
    @media (min-width: $tablet) {
        padding: 0.8rem 0;
    }
}

.subwrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $tablet) {
        flex-direction: row;
    }
}

.subwrapper_responsive {
    display: flex;
    width: 100%;

    @media (min-width: $tablet) {
        width: 21rem;
    }
}

.wrapper_checkBox {
    display: flex;
}

.wrapper_subject {
    text-align: left;
    width: 100%;

    @media (min-width: $tablet) {
        margin: auto auto auto 0;
        text-align: left;
        width: auto;
    }
}

.wrapper_item {
    text-align: left;
    width: 50%;

    @media (min-width: $tablet) {
        margin: auto 0;
        text-align: center;
    }
}

.wrapper_tag {
    width: 100%;
    text-align: center;
    margin: 0.8rem 0;

    @media (min-width: $tablet) {
        margin: auto 0;
        text-align: center;
        width: 18.4rem;
        padding-left: 1.6rem;
    }
}

.title {
    text-align: left;
    color: $bg-color;
    margin: 0.8rem 0 0 0;
    margin: 0;

    @media (min-width: $tablet) {
        margin: auto 0;
    }
}

.text {
    font-size: $comment;
    font-weight: $regular;
    color: $accent-color;
    text-align: left;
    margin: 0.8rem 0 0 0;

    @media (min-width: $tablet) {
        text-align: center;
        margin: unset;
    }
}

.gap {
    margin: auto 1.6rem;
}

.tx_to_justify {
    color: darken($to-justify-color, 2%);
}

.bg_to_justify {
    background-color: lighten($to-justify-color, 38%);
}

.tx_unjustified {
    color: $error;
}

.bg_unjustified {
    background-color: lighten($unjustified-color, 15%);
}

.bg_absence_lateness {
    background-color: lighten($absence-lateness-color, 15%);
}

.tx_processing {
    color: $cre-color;
}

.bg_procession {
    background-color: lighten($cre-color, 55%);
}

.tx_justified {
    color: $justified-color;
}

.bg_justified {
    background-color: $justified-bg-color
}

.tx_absence_lateness {
    color: $absence-lateness-color;
}

.containerMotiv {
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 0 3.2rem;
    margin-bottom: 3.2rem;
}

.warning_icon_color {
    filter: $icon-warning;
}

.legend {
    gap: 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px; /* Espace entre les colonnes */
}

.legend_titles{
    color :red; 
    font-size: 15px; 
    text-align: center;
    font-weight: bold;

}

.legend_defenition{
    color :rgb(1, 1, 1); 
    font-size: 12px; 
    text-align: start;
    padding: 20px;
    
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    border-radius: 0.8rem;
    padding: 0 3.2rem;
    margin-bottom: 3.2rem;
}
.legend_littletitle
{
    color :rgb(1, 1, 1); 
    font-size: 11px; 
    text-align: start;
    
}

.link
{
    color :rgb(1, 1, 1); 
    font-size: 14px; 
    text-align: start;
    padding-left: 2em;
    
}
