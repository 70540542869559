@import "../../variables";

.container {
    width: 80%;
    display: flex;
    flex-direction: column;
    background-color: $lbg-color;
    margin: 3.2rem auto;
    border-radius: 0.8rem;
}

.wrapper {
    padding: 3.2rem;
}

.title {
    font-size: $h4;
    color: $accent-color;
    margin: 1.6rem 0 4.8rem 0;
    text-align: center;
}

.subtitle {
    font-size: $h6;
    color: $accent-color;
    margin: 2.8rem 0 4.2rem 0;
    text-align: center;
}

.part_subtitle {
    font-size: $h6;
    color: $accent-color;
    margin: 0 0 3.2rem 0;
    text-align: center;
}

.section_title {
    font-size: 1.8rem;
    color: $accent-color;
    margin: 2.8rem 0 1.6rem 0;
}

.left_align {
    text-align: left;
    font-size: 1.8rem;
    font-weight: 400;
}

.disclaimer {
    font-size: $comment;
    color: $accent-color;
}

.section {
    display: grid;
    grid-gap: 2.4rem;
    grid-template-rows: repeat(1, 1fr);
    margin: 3.2rem auto;

    @media (min-width: $landscape) {
        grid-gap: 3rem;
        grid-template-columns: repeat(3, 3fr);
    }

    @media (min-width: $desktop) {
        grid-template-columns: repeat(3, 3fr);
        margin: 3.2rem auto 0 auto;
    }
}

.section_alt {
    width: 100%;
    display: inline-grid;
    grid-gap: 2.4rem;
    grid-template-rows: repeat(1, 1fr);
    margin: 3.2rem auto;

    @media (min-width: $landscape) {
        grid-gap: 3rem;
        grid-template-columns: repeat(2, 2fr);
    }

    @media (min-width: $desktop) {
        grid-template-columns: repeat(2, 2fr);
        margin: 3.2rem auto 0 auto;
    }
}

.comments_section {
    margin: 3.2rem 0 6rem 0;
}

.grades_section {
    display: flex;
    flex-direction: column;
}

.grades_container {
    width: 100%;
    display: inline-grid;
    grid-template-rows: repeat(1, 1fr);
    row-gap: 3.2rem;
    column-gap: 4.8rem;
    margin: 3.2rem auto;

    @media (min-width: $landscape) {
        grid-template-columns: repeat(3, 3fr);
    }

    @media (min-width: $desktop) {
        width: 94%;
        margin: auto;
        grid-template-columns: repeat(3, 3fr);
        margin: 0 auto 4.8rem auto;
    }
}

.grade_wrapper {
    display: flex;
    width: 85%;
    margin: auto;
}

.grades_block_title {
    font-size: 2.1rem;
    font-weight: 400;
    color: $accent-color;
    margin: 0 auto 4.2rem auto;
}

.grade_title {
    font-size: 4.8rem;
    font-weight: 300;
    color: $accent-color;
    margin: 0 1.6rem 0 0;
}

.grade_text_wrap {
    display: inherit;
    flex-direction: column;
    margin: 0.6rem 0 auto 0;
}

.grade_mention {
    font-size: $p;
    color: $accent-color;
    margin: 0;
}

.grade_description {
    font-size: 1.2rem;
    margin: 0.4rem 0 0 0;
}

.closure_subtitle {
    width: 70%;
    font-size: 2.1rem;
    text-align: center;
    font-weight: 400;
    color: $accent-color;
    margin: 0 auto 4.2rem auto;
}

.closure_subtitle {
    width: 70%;
    font-size: 2.1rem;
    text-align: center;
    font-weight: 400;
    color: $accent-color;
    margin: 0 auto 4.2rem auto;
}

.closure_warning {
    text-align: center;
    font-weight: 400;
    color: $accent-color;
    margin: 0 auto 4.2rem auto;
}

.closure_btn_section {
    display: flex;
    flex-direction: column;
}

.btn_section {
    display: flex;
    justify-content: space-evenly;
    align-content: space-around;
    align-items: center;
    margin: 3.2rem 0 4.8rem 0;
}

.btn_wrapper {
    display: flex;
}

/*
    SOLID BUTTON STYLES
*/

.btn_active {
    background-color: $warning;
    box-shadow: $card-shadow;
    height: 4.8rem;
    // margin: auto auto auto 0.8rem;
    border-radius: 3rem;
}

.btn_active:hover {
    background-color: darken($warning, 10%);
}

.btn_text {
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
    margin: auto;
}

.btn_icon {
    filter: $icon-white;
}

.outlined_btn_icon {
    filter: invert(39%) sepia(70%) saturate(1126%) hue-rotate(195deg) brightness(105%) contrast(91%);
    transform: rotate(180deg);
}

.btn_active_closure {
    background-color: $calendar-red;
    box-shadow: $card-shadow;
    height: 4.8rem;
    margin: auto auto 3.2rem auto;
    border-radius: 3rem;
}

.btn_active_disabled {
    background-color: rgb(196, 140, 140);
    box-shadow: $card-shadow;
    height: 4.8rem;
    margin: auto auto 3.2rem auto;
    border-radius: 3rem;
}

.btn_active_closure:hover {
    background-color: darken($calendar-red, 10%);
}

.btn_active_closure_text {
    color: $secondary-color;
    font-size: $p;
    font-weight: $semi-bold;
    margin: auto;
}

/*
    OUTLINE BUTTON STYLES
*/

.btn_active_outlined {
    background-color: transparent;
    border: 0;
    height: 4.8rem;
    // margin: auto 0.8rem auto auto;
    border-radius: 3rem;
}

.btn_active_outlined:hover {
    color: darken($input-underline, 100%);
}

.btn_text_outlined {
    color: $input-underline;
    font-size: $p;
    font-weight: $semi-bold;
    margin: auto;
}

/*
    TEXT AREA STYLES
*/
.textArea_borderDisabled {
    font-family: 'Roboto';
    font-size: 1.6rem;
    font-weight: $regular;
    color: $accent-color;
    background-color: #9797975e;
    -webkit-appearance: none;
    border: 0;
    border: 1px solid #ced4da;
    width: 100%;
}

.textArea_border {
    font-family: 'Roboto';
    font-size: 1.6rem;
    font-weight: $regular;
    color: $accent-color;
    background-color: $lbg-color;
    -webkit-appearance: none;
    border: 0;
    border: 1px solid #ced4da;
    width: 100%;
}

.textArea_border:focus {
    outline: none;
    border: 1px solid #ced4da;;
    filter: none;
    box-shadow: 0 1px 0 0 #4285f4;
    border-bottom: 1px solid #4285f4;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    outline: 0;
}

.textArea_border::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aeb4b9;
    opacity: 1;
    font-weight: 400;
}

.textArea_border:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aeb4b9;
}

.textArea_border::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #aeb4b9;
}

.radio {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
}

.radioSection {
    display: flex;
    justify-content: space-evenly;
    font-size: 1.3rem;
    flex-wrap: wrap;
}

.checkBox {
    font-size: $comment;
    display: inline-flex;
    position: relative;
    margin: auto 0;
    width: 3.8rem;
    height: 4.8rem;
    user-select: none;
    cursor: pointer;
}
  
  /* Hide the browser's default checkbox */
  .checkBox input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    height: 2rem;
    width: 2rem;
    border: 0.1rem solid $primary-color;
    margin: auto auto auto 0;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkBox input:checked ~ .checkmark {
    background: $ok;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    top: 2rem;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkBox input:checked ~ .checkmark:after {
    display: inline-flex;
  }
  
  /* Style the checkmark/indicator */
  .checkBox .checkmark:after {
    left: 0.7rem;
    top: 1.6rem;
    width: 0.5rem;
    height: 1rem;
    border: solid white;
    border-width: 0 0.3rem 0.3rem 0;
    transform: rotate(45deg);
  }
  
  .boxStylingDisabled {
    font-family: 'Roboto';
    font-size: 1.6rem;
    font-weight: $regular;
    color: $accent-color;
    height: 3.8rem;
    background-color: #9797975e;
    -webkit-appearance: none;
    border: 0;
    // border-radius: 0.6rem;
    // padding-left: 1rem;
    border-bottom: 1px solid #ececec;
    width: 100%;
  }

  .boxStylingDisabled:focus {
    outline: none;
    border: 0;
    filter: none;
    box-shadow: 0 1px 0 0 #4285f4;
    border-bottom: 1px solid #4285f4;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    outline: 0;
}

.boxStylingDisabled::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aeb4b9;
    /* padding-left: 1rem; */
    opacity: 1; /* Firefox */
    font-weight: 400;
}

.boxStylingDisabled:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aeb4b9;
}

.boxStylingDisabled::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #aeb4b9;
}

.spanErrorInfos {
    color: red;
    font-size: 1.4rem;
    font-style: italic;
}

.missingInformation {
    color: red;
    font-size: 1.4rem;
    text-align: center;
}